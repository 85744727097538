<template>
  <div
    class="popup_wrap"
    style="width:500px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      {{ $t('msg.CMBK100_M1.038') }}
    </button> <!-- close -->
    <div class="popup_cont">
      <h1 class="page_title">
        {{ $t('msg.ONEX030P020.008') }}<!-- Booking Cancel 사유 -->
      </h1>
      <div class="content_box">
        <!-- content_box -->
        <table class="tbl_row fir_td">
          <colgroup>
            <col width="*">
          </colgroup>
          <tbody>
            <tr
              v-for="(item,index) in items"
              :key="index"
            >
              <td class="text_left">
                <input
                  :id="item.cnclRsnCd"
                  v-model="radioValues"
                  type="radio"
                  :value="item.cnclRsnCd"
                  @change.prevent="checkedCancelReason"
                >
                <label :for="item.cnclRsnCd"><span>{{ item.cnclRsnDsc }}</span></label>
              </td>
            </tr>
            <tr v-if="radioValues === '08'">
              <textarea
                v-model="etc"
                class="textarea"
              />
            </tr>
          </tbody>
        </table>
        <p
          v-if="radioValues === '01'"
          class="txt_desc asterisk"
        >
          {{ $t('msg.ONEX030P020.002') }}
        </p> <!-- 부킹 화면으로 이동하여 스케줄을 변경하시려면 아래 Booking Detail를 <br>선택해주세요. -->
        <p
          v-else-if="radioValues === '02'"
          class="txt_desc asterisk"
        >
          {{ $t('msg.ONEX030P020.003') }}
        </p> <!-- 서류 마감 연장 가능 여부 확인 후 Booking Cancel 혹은 Booking Detail에서 스케줄 변경 바랍니다. -->
        <p
          v-else-if="radioValues === '04'"
          class="txt_desc asterisk"
        >
          {{ $t('msg.ONEX030P020.004') }}
        </p> <!-- 부킹 화면으로 이동하여 스케줄을 변경하시려면 아래 Booking Detail을 선택해주세요. -->
      </div>
      <!-- content_box // -->
      <div class="mt10 text_center">
        <a
          class="button blue lg"
          href="#"
          @click.prevent="bookingCancel"
        >
          {{ $t('msg.ONEX030P020.001') }}
        </a> <!-- Booking Cancel -->
        <a
          v-if="isDetailBtn()"
          class="button blue lg"
          href="#"
          @click.prevent="moveBookingDetailPage"
        >
          {{ $t('msg.ONEX030P020.005') }}
        </a> <!-- Booking Detail -->
        <a
          class="button gray lg"
          href="#"
          @click.prevent="$emit('close')"
        >
          {{ $t('msg.CMBK100_M1.038') }}
        </a> <!-- Close -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>
<script>

import bookings from '@/api/rest/trans/bookings'

export default {
  name: 'BookingCancelPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bkgNo: '',
          form: {
            cboTerm: '',
            overUi: '',
            cancelChargeYn: '',
            lclCancelYn: ''
          }
        }
      }
    }
  },
  data () {
    return {
      items: [],
      bkgNo: '',
      radioValues: '',
      cancelReason: '',
      etc: '',
      cnclYn: ''
    }
  },
  //부킹 리스트 초기화 셋팅
  created: function () {
    bookings.getBookingCancelList().then((data) => {
      const list = data.data.list
      this.items = list
    })
  },
  methods: {
    //Booking Cancel 클릭 - 부킹 취소
    bookingCancel () {
      //지선책임님 작업 후 진행
      //기존 페이지의 booking_cancel_form에 값을 넘겨서 fnBookingCancel_post() 호출
      //부킹수정에서는 bookingedit.js에서 fnBookingCancel() 호출
      if (this.radioValues) {
        if (this.radioValues === '08' && !this.etc) {
          alert(this.$t('msg.ONEX030P020.006')) // 기타 사유를 입력해주세요.
          return
        }
      } else {
        alert(this.$t('msg.ONEX030P020.007')) // Booking Cancel 사유를 선택해주세요.
        return
      }

      const props = this.parentInfo

      this.cnclYn = 'Y'

      const formData = {
        bkgNo: props.bkgNo, //부킹넘버
        cboTerm: props.cboTerm, //ASIS : polShtm
        lclCancelYn: props.lclCancelYn, //LCL Booking 여부(TOBE때 미사용 예정)
        cnclRsnCd: this.radioValues, //부킹사유코드
        cnclRsnDsc: this.cancelReason, //부킹사유
        cnclRsnRmk: this.etc, //부킹사유(기타시 입력값)
        cnclYn: this.cnclYn, //부킹캔슬 플래그
        cancelChargeYn: props.cancelChargeYn, //태국 웹 부킹 취소시
        overUi: props.overUi // Y인경우 선적확정에서 부킹캔슬
      }

      let successFlag = true

      bookings.bookingCancel(formData).then((item) => {
        console.log(item)
        if (!item) {
          successFlag = false
        }
      }).catch((err) => {
        console.log(err)
        successFlag = false
      }).finally(() => {
        const TH = this
        let message = successFlag === true ? TH.$t('msg.CSBK100.384') : TH.$t('msg.CSBK100.385')
        this.$ekmtcCommon.alertCallback(message, function () {
          TH.$emit('selectFunc')
          TH.$emit('close')
        })
      })
    },
    //Booking Detail 클릭 - 부킹 수정 화면 이동
    moveBookingDetailPage () {
      this.$emit('close')
      this.$router.push({ name: 'booking-edit', params: { bkgNo: this.parentInfo.bkgNo } }).catch(() => {})
    },
    isDetailBtn () {
      let isVisible = false

      if (this.$route.name !== 'booking-edit') {
        isVisible = true
      }

      return isVisible
    },
    //부킹취소사유 선택시 코드에 맞는 사유 셋팅
    checkedCancelReason () {
        const filteredItem = this.items.filter((item) => item.cnclRsnCd === this.radioValues)
        this.cancelReason = filteredItem[0].cnclRsnDsc
    }
  }
}
</script>
