var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("msg.CMBK100_M1.038")) + " ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(" " + _vm._s(_vm.$t("msg.ONEX030P020.008"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_row fir_td" }, [
            _vm._m(0),
            _c(
              "tbody",
              [
                _vm._l(_vm.items, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", { staticClass: "text_left" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.radioValues,
                            expression: "radioValues",
                          },
                        ],
                        attrs: { id: item.cnclRsnCd, type: "radio" },
                        domProps: {
                          value: item.cnclRsnCd,
                          checked: _vm._q(_vm.radioValues, item.cnclRsnCd),
                        },
                        on: {
                          change: [
                            function ($event) {
                              _vm.radioValues = item.cnclRsnCd
                            },
                            function ($event) {
                              $event.preventDefault()
                              return _vm.checkedCancelReason.apply(
                                null,
                                arguments
                              )
                            },
                          ],
                        },
                      }),
                      _c("label", { attrs: { for: item.cnclRsnCd } }, [
                        _c("span", [_vm._v(_vm._s(item.cnclRsnDsc))]),
                      ]),
                    ]),
                  ])
                }),
                _vm.radioValues === "08"
                  ? _c("tr", [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.etc,
                            expression: "etc",
                          },
                        ],
                        staticClass: "textarea",
                        domProps: { value: _vm.etc },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.etc = $event.target.value
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm.radioValues === "01"
            ? _c("p", { staticClass: "txt_desc asterisk" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.ONEX030P020.002")) + " "),
              ])
            : _vm.radioValues === "02"
            ? _c("p", { staticClass: "txt_desc asterisk" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.ONEX030P020.003")) + " "),
              ])
            : _vm.radioValues === "04"
            ? _c("p", { staticClass: "txt_desc asterisk" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.ONEX030P020.004")) + " "),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.bookingCancel.apply(null, arguments)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("msg.ONEX030P020.001")) + " ")]
          ),
          _vm._v(" "),
          _vm.isDetailBtn()
            ? _c(
                "a",
                {
                  staticClass: "button blue lg",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.moveBookingDetailPage.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("msg.ONEX030P020.005")) + " ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("msg.CMBK100_M1.038")) + " ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "*" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }